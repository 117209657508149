import * as React from "react"
import { Link } from "gatsby"
import logo from "../images/skillmeter_logo_blue.png";

// styles
const pageStyles = {
  color: "#232129",
  padding: "96px",
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
}
const headingStyles = {
  marginTop: 0,
  marginBottom: 64,
  maxWidth: 320,
}

const paragraphStyles = {
  marginBottom: 48,
}
const codeStyles = {
  color: "#8A6534",
  padding: 4,
  backgroundColor: "#FFF4DB",
  fontSize: "1.25rem",
  borderRadius: 4,
}

// markup
const NotFoundPage = () => {
  return (
    <main style={pageStyles}>
      <title>Not found</title>

      <div className="container mx-auto justify-center mt-10 whitespace-pre">

      <a href='https://skillmeter.com' ><img className="h-8  mb-10 " src={logo} alt="Skillmeter" /></a>

      <h1 style={headingStyles} className='font-bold'>Page not found</h1>
      <p style={paragraphStyles}>
        Sorry, we couldn’t find what you were looking for.
        <br /><br />
        ⚠️ If you arrived here from a legacy results link, please sign in to your account to access the candidate results.
        <br />
        {process.env.NODE_ENV === "development" ? (
          <>
            <br />
            Try creating a page in <code style={codeStyles}>src/pages/</code>.
            <br />
          </>
        ) : null}
        <br />
        <Link to="/">👉 Go home and start from there</Link>.
      </p>
      </div>

    </main>
  )
}

export default NotFoundPage
